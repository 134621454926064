<template>
    <div style="width: 100%; height: auto;">
        <v-card-text class="pt-0 px-2">
            <v-subheader style="height: 38px;" class="px-0 mt-2">
                Templates
                <v-spacer></v-spacer>
                <v-menu bottom offset-y :close-on-content-click="false" v-if="selectedTemplate && selectedTemplate.type && (selectedTemplate?.type == 'DefaultTemplate' ? ['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x)) : true)"> 
                    <template v-slot:activator="{on, attrs}">
                        <v-chip small class="px-1 mr-1" v-bind="attrs" v-on="on" outlined style="border: none;"><v-icon small color="primary">save</v-icon></v-chip>
                    </template>
                    <v-card style="background: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px;">
                        <v-toolbar flat dense height="35px">
                            <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                Update Template
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pt-0">
                            <div style="width: 100%;flex-direction: column;" class="d-flex justify-center align-center mb-3">
                                <span style="font-size: 12px;" class="mb-1"> Current Template</span>
                                <v-card elevation-12 style="width: 280px; border-radius: 8px; background: var(--v-primary-darken2) !important;" class="d-flex align-center pa-1">
                                    <v-card height="100%" style=" border-radius: 8px; aspect-ratio: 1/1; min-height:65px; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                        <v-icon>{{selectedTemplate.templateType == 'table' ? 'reorder' : selectedTemplate.templateType == 'dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                        <span style="font-size: 10px; line-height: 1;">{{selectedTemplate.templateType}}</span>
                                    </v-card>   
                                    <div>
                                        <span style="font-size: 12px; color: var(--v-primaryText-darken1); display: block; line-height: 1;">{{ selectedTemplate.title }}</span>
                                        <div style="font-size: 10px; color: var(--v-primaryText-darken1); white-space: break-spaces; line-height: 1.5;" >{{ selectedTemplate.description }}</div>
                                    </div>
                                </v-card>
                            </div>
                            <v-text-field v-model="selectedTemplate.title" :maxLength="25" outlined dense hide-details label="Title" placeholder="Exporter View" persistent-placeholder class="mb-4"></v-text-field>
                            <v-textarea v-model="selectedTemplate.description" rows="3" :maxLength="100" outlined dense hide-details label="Description" placeholder="Exporter Perspective" persistent-placeholder></v-textarea>
                            <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideAvailableTags">
                                Available To
                            </v-subheader>
                            <v-divider v-if="!hideAvailableTags"></v-divider>
                            <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideAvailableTags">
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Everyone')" :color="selectedTemplate.allowedParties.includes('Everyone') ? 'tertiary' : ''"  @click="templateTag('Everyone', null, 'selected')">Everyone</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Forwarder')" :color="selectedTemplate.allowedParties.includes('Forwarder') ? 'tertiary' : ''"  @click="templateTag('Forwarder', null, 'selected')">Forwarder</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Shipper')" :color="selectedTemplate.allowedParties.includes('Shipper') ? 'tertiary' : ''"  @click="templateTag('Shipper', null, 'selected')">Shipper</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Consignee')" :color="selectedTemplate.allowedParties.includes('Consignee') ? 'tertiary' : ''"  @click="templateTag('Consignee', null, 'selected')">Consignee</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Buyer')" :color="selectedTemplate.allowedParties.includes('Buyer') ? 'tertiary' : ''"  @click="templateTag('Buyer', null, 'selected')">Buyer</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Transporter')" :color="selectedTemplate.allowedParties.includes('Transporter') ? 'tertiary' : ''"  @click="templateTag('Transporter', null, 'selected')">Transporter</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Shipper')" :color="selectedTemplate.allowedParties.includes('On Behalf Shipper') ? 'tertiary' : ''"  @click="templateTag('On Behalf Shipper', null, 'selected')">On Behalf Shipper</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Consignee')" :color="selectedTemplate.allowedParties.includes('On Behalf Consignee') ? 'tertiary' : ''"  @click="templateTag('On Behalf Consignee', null, 'selected')">On Behalf Consignee</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Forwarder')" :color="selectedTemplate.allowedParties.includes('On Behalf Forwarder') ? 'tertiary' : ''"  @click="templateTag('On Behalf Forwarder', null, 'selected')">On Behalf Forwarder</v-chip>
                                <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Buyer')" :color="selectedTemplate.allowedParties.includes('On Behalf Buyer') ? 'tertiary' : ''"  @click="templateTag('On Behalf Buyer', null, 'selected')">On Behalf Buyer</v-chip>
                            </div>
                            <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideCategoryTags">
                                Tags
                            </v-subheader>
                            <v-divider v-if="!hideCategoryTags"></v-divider>
                            <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideCategoryTags">
                                <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Logistical Docs')" :color="selectedTemplate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags', 'selected')">Logistical Docs</v-chip>
                                <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Finance')" :color="selectedTemplate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags', 'selected')">Finance</v-chip>
                                <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Bookings')" :color="selectedTemplate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags', 'selected')">Bookings</v-chip>
                                <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Clients')" :color="selectedTemplate.tags.includes('Clients') ? 'greyBase' : ''" small @click="templateTag('Clients', 'tags', 'selected')">Clients</v-chip>
                            </div>
                            <div class="mt-3 d-flex justify-center align-center">
                                <v-btn color="primary" :disabled="!selectedTemplate.title" @click="updateTemplate(selectedTemplate.type)" :loading="loadingTemplateSave">Update</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-menu>
                <v-tooltip top open-delay="2000" v-if="selectedTemplate?.title && ((selectedTemplate?.type == 'UserTemplate') || (selectedTemplate?.type == 'OrgTemplate' && ['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))))">
                    <template v-slot:activator="{on}">
                        <v-chip outlined style="border: none;" v-on="on" small  class="px-1"  @click="deletedSelectedTemplate" v-if="selectedTemplate?.title"><v-icon small color="var(--v-redPop-darken1)">delete</v-icon></v-chip>
                    </template>
                    Delete Current Template
                </v-tooltip>
            </v-subheader>
            <v-divider></v-divider>
            <v-subheader style="height: 25px;" class="px-0">
                {{$store.state.currentOrg.alias ?? $store.state.currentOrg.name}} Templates
            </v-subheader>
            <v-divider></v-divider>
            <v-row no-gutters v-if="!loadingTemplates">
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center" style="height: 40px;" v-if="(!orgTemplates.length || orgTemplates.length == 0) && !['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))" >
                    No Available Templates
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" v-for="(template, index) in orgTemplates" :key="index" class="px-1 py-1" v-else>
                    <v-hover v-slot="{ hover }">
                        <v-card @click="changeTemplate(template, 'OrgTemplate')" v-if="template && template.templateType" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="cursor: pointer; border-radius: 8px; width: 100%;" :style="selectedTemplate?.id == template.id ? {'background': 'var(--v-primary-darken2) !important'} : {'background': 'var(--v-greyRaised-lighten2) !important'}">
                            <v-card height="100%" style=" border-radius: 8px; aspect-ratio: 1/1; min-height: 36px; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                <v-icon small>{{template.templateType == 'Table' ? 'reorder' : template.templateType == 'Dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                <span style="font-size: 10px; line-height: 1;">{{template.templateType}}</span>
                            </v-card>
                            <div>
                                <span style="font-size: 12px; color: var(--v-primaryText-darken1); display: block; line-height: 1;">{{ template.title }}</span>
                                <div style="font-size: 10px; color: var(--v-primaryText-darken1); white-space: break-spaces; line-height: 1.5;" >{{ template.description }}</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center px-1 py-1" v-if="['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))">
                    <v-menu bottom offset-y :close-on-content-click="false">
                        <template v-slot:activator="{on, attrs}">
                            <v-hover v-slot="{ hover }">
                                <v-card v-on="on" v-bind="attrs" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center justify-center" style="border: 1px dashed rgb(135, 135, 135); cursor: pointer; border-radius: 8px; width: 100%; background: var(--v-greyRaised-lighten1) !important">
                                    <v-icon size="28" style="opacity: 0.2;">add_circle</v-icon>
                                </v-card>
                            </v-hover>
                        </template>
                        <v-card style="background: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px; ">
                            <v-toolbar flat dense height="35px">
                                <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                    Create Template
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-text-field v-model="templateCreate.title" :maxLength="25" outlined dense hide-details label="Title" placeholder="Exporter View" persistent-placeholder class="mb-4"></v-text-field>
                                <v-textarea v-model="templateCreate.description" rows="3" :maxLength="100" outlined dense hide-details label="Description" placeholder="Exporter Perspective" persistent-placeholder></v-textarea>
                                <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideAvailableTags"> 
                                    Available To
                                </v-subheader>
                                <v-divider v-if="!hideAvailableTags"></v-divider>
                                <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideAvailableTags">
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Everyone')" :color="templateCreate.allowedParties.includes('Everyone') ? 'tertiary' : ''"  @click="templateTag('Everyone')">Everyone</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Forwarder')" :color="templateCreate.allowedParties.includes('Forwarder') ? 'tertiary' : ''"  @click="templateTag('Forwarder')">Forwarder</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Shipper')" :color="templateCreate.allowedParties.includes('Shipper') ? 'tertiary' : ''"  @click="templateTag('Shipper')">Shipper</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Consignee')" :color="templateCreate.allowedParties.includes('Consignee') ? 'tertiary' : ''"  @click="templateTag('Consignee')">Consignee</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Buyer')" :color="templateCreate.allowedParties.includes('Buyer') ? 'tertiary' : ''"  @click="templateTag('Buyer')">Buyer</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Transporter')" :color="templateCreate.allowedParties.includes('Transporter') ? 'tertiary' : ''"  @click="templateTag('Transporter')">Transporter</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Shipper')" :color="templateCreate.allowedParties.includes('On Behalf Shipper') ? 'tertiary' : ''"  @click="templateTag('On Behalf Shipper')">On Behalf Shipper</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Consignee')" :color="templateCreate.allowedParties.includes('On Behalf Consignee') ? 'tertiary' : ''"  @click="templateTag('On Behalf Consignee')">On Behalf Consignee</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Forwarder')" :color="templateCreate.allowedParties.includes('On Behalf Forwarder') ? 'tertiary' : ''"  @click="templateTag('On Behalf Forwarder')">On Behalf Forwarder</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Buyer')" :color="templateCreate.allowedParties.includes('On Behalf Buyer') ? 'tertiary' : ''"  @click="templateTag('On Behalf Buyer')">On Behalf Buyer</v-chip>
                                </div>
                                <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideCategoryTags">
                                    Tags
                                </v-subheader>
                                <v-divider v-if="!hideCategoryTags"></v-divider>
                                <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideCategoryTags">
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Logistical Docs')" :color="templateCreate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags')">Logistical Docs</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Finance')" :color="templateCreate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags')">Finance</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Bookings')" :color="templateCreate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags')">Bookings</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Client')" :color="templateCreate.tags.includes('Client') ? 'greyBase' : ''" small @click="templateTag('Client', 'tags')">Client</v-chip>
                                </div>
                                <div class="mt-3 d-flex justify-center align-center">
                                    <v-btn color="primary" :disabled="!templateCreate.title" @click="saveTemplate('OrgTemplate')" :loading="loadingTemplateSave">Save</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="loadingTemplates">
                <v-col cols="12" sm="12" md="6" lg="6" v-for="(i) in 4" :key="i" class="px-1 py-1">
                    <v-card flat class="d-flex pa-1 align-center" style="border-radius: 8px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten2) !important;">
                        <div style="max-height: 67px; max-width: 67px; height: 67px; width: 67px; contain: content; border-radius: 8px; overflow: hidden;">
                            <v-skeleton-loader type="image"></v-skeleton-loader>
                        </div>
                        <v-skeleton-loader class="ml-2" style="width: 60%;" type="sentences"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>
            <v-subheader style="height: 25px;" class="px-0">
                Your Templates
            </v-subheader>
            <v-divider></v-divider>
            <v-row no-gutters v-if="!loadingTemplates">
                <v-col cols="12" sm="12" md="12" lg="12" v-for="(template, index) in userTemplates" :key="index" class="px-1 py-1">
                    <v-hover v-slot="{ hover }">
                        <v-card @click="changeTemplate(template, 'UserTemplate')" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="cursor: pointer; border-radius: 8px; width: 100%;" :style="selectedTemplate?.id == template.id ? {'background': 'var(--v-primary-darken2) !important'} : {'background': 'var(--v-greyRaised-lighten2) !important'}">
                            <v-card height="100%" style=" border-radius: 8px; aspect-ratio: 1/1; min-height: 36px; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                <v-icon small>{{template.templateType == 'Table' ? 'reorder' : template.templateType == 'Dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                <span style="font-size: 10px; line-height: 1;">{{template.templateType}}</span>
                            </v-card>
                            <div>
                                <span style="font-size: 12px; color: var(--v-primaryText-darken1); display: block; line-height: 1;">{{ template.title }}</span>
                                <div style="font-size: 10px; color: var(--v-primaryText-darken1); white-space: break-spaces; line-height: 1.5;" >{{ template.description }}</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center px-1 py-1" align-self="center">
                    <v-menu bottom offset-y :close-on-content-click="false">
                        <template v-slot:activator="{on, attrs}">
                            <v-hover v-slot="{ hover }">
                                <v-card v-on="on" v-bind="attrs" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center justify-center" style="border: 1px dashed rgb(135, 135, 135); cursor: pointer; border-radius: 8px; width: 100%; background: var(--v-greyRaised-lighten1) !important">
                                    <v-icon size="28" style="opacity: 0.2;">add_circle</v-icon>
                                </v-card>
                            </v-hover>
                        </template>
                        <v-card style="background-color: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px;">
                            <v-toolbar flat dense height="35px">
                                <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                    Create Template
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-text-field v-model="templateCreate.title" :maxLength="25" outlined dense hide-details label="Title" placeholder="My Template" persistent-placeholder class="mb-4"></v-text-field>
                                <v-textarea v-model="templateCreate.description" rows="3" :maxLength="100" outlined dense hide-details label="Description" placeholder="How I prefer my layout" persistent-placeholder></v-textarea>
                                <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideCategoryTags">
                                    Tags
                                </v-subheader>
                                <v-divider v-if="!hideCategoryTags"></v-divider>
                                <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideCategoryTags">
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Logistical Docs')" :color="templateCreate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags')">Logistical Docs</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Finance')" :color="templateCreate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags')">Finance</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Bookings')" :color="templateCreate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags')">Bookings</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Client')" :color="templateCreate.tags.includes('Client') ? 'greyBase' : ''" small @click="templateTag('Client', 'tags')">Client</v-chip>
                                </div>
                                <div class="mt-3 d-flex justify-center align-center">
                                    <v-btn color="primary" :disabled="!templateCreate.title" @click="saveTemplate('UserTemplate')" :loading="loadingTemplateSave">Save</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="loadingTemplates">
                <v-col cols="12" sm="12" md="6" lg="6" v-for="(i) in 4" :key="i" class="px-1 py-1">
                    <v-card flat class="d-flex pa-1 align-center" style="border-radius: 8px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten2) !important;">
                        <div style="max-height: 67px; max-width: 67px; height: 67px; width: 67px; contain: content; border-radius: 8px; overflow: hidden;">
                            <v-skeleton-loader type="image"></v-skeleton-loader>
                        </div>
                        <v-skeleton-loader class="ml-2" style="width: 60%;" type="sentences"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>
            <v-subheader style="height: 25px;" class="px-0">
                Default Templates
            </v-subheader>
            <v-divider></v-divider>
            <v-row no-gutters v-if="!loadingTemplates">
                <v-col cols="12" sm="12" md="12" lg="12" v-for="(template, index) in defaultTemplates" :key="index" class="px-1 py-1">
                    <v-hover v-slot="{ hover }">
                        <v-card @click="changeTemplate(template, 'DefaultTemplate')" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="cursor: pointer; border-radius: 8px; width: 100%;" :style="selectedTemplate?.id == template.id ? {'background': 'var(--v-primary-darken2) !important'} : {'background': 'var(--v-greyRaised-lighten2) !important'}">
                            <v-card height="100%" style=" border-radius: 8px; aspect-ratio: 1/1; min-height: 36px; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                <v-icon small>{{template.templateType == 'Table' ? 'reorder' : template.templateType == 'Dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                <span style="font-size: 10px; line-height: 1;">{{template.templateType}}</span>
                            </v-card>
                            <div>
                                <span style="font-size: 12px; color: var(--v-primaryText-darken1); display: block; line-height: 1;">{{ template.title }}</span>
                                <div style="font-size: 10px; color: var(--v-primaryText-darken1); white-space: break-spaces; line-height: 1.5;" >{{ template.description }}</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center px-1 py-1" align-self="center" v-if="['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))">
                    <v-menu bottom offset-y :close-on-content-click="false">
                        <template v-slot:activator="{on, attrs}">
                            <v-hover v-slot="{ hover }">
                                <v-card v-on="on" v-bind="attrs" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center justify-center" style="border: 1px dashed rgb(135, 135, 135); cursor: pointer; border-radius: 8px; width: 100%; background: var(--v-greyRaised-lighten1) !important">
                                    <v-icon size="28" style="opacity: 0.2;">add_circle</v-icon>
                                </v-card>
                            </v-hover>
                        </template>
                        <v-card style="background-color: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px;">
                            <v-toolbar flat dense height="35px">
                                <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                    Create Template
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-text-field v-model="templateCreate.title" :maxLength="25" outlined dense hide-details label="Title" placeholder="Exporter View" persistent-placeholder class="mb-4"></v-text-field>
                                <v-textarea v-model="templateCreate.description" rows="3" :maxLength="100" outlined dense hide-details label="Description" placeholder="Exporter Perspective" persistent-placeholder></v-textarea>
                                <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideAvailableTags"> 
                                    Available To
                                </v-subheader>
                                <v-divider v-if="!hideAvailableTags"></v-divider>
                                <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideAvailableTags">
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Everyone')" :color="templateCreate.allowedParties.includes('Everyone') ? 'tertiary' : ''"  @click="templateTag('Everyone')">Everyone</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Forwarder')" :color="templateCreate.allowedParties.includes('Forwarder') ? 'tertiary' : ''"  @click="templateTag('Forwarder')">Forwarder</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Shipper')" :color="templateCreate.allowedParties.includes('Shipper') ? 'tertiary' : ''"  @click="templateTag('Shipper')">Shipper</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Consignee')" :color="templateCreate.allowedParties.includes('Consignee') ? 'tertiary' : ''"  @click="templateTag('Consignee')">Consignee</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Buyer')" :color="templateCreate.allowedParties.includes('Buyer') ? 'tertiary' : ''"  @click="templateTag('Buyer')">Buyer</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Transporter')" :color="templateCreate.allowedParties.includes('Transporter') ? 'tertiary' : ''"  @click="templateTag('Transporter')">Transporter</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Shipper')" :color="templateCreate.allowedParties.includes('On Behalf Shipper') ? 'tertiary' : ''"  @click="templateTag('On Behalf Shipper')">On Behalf Shipper</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Consignee')" :color="templateCreate.allowedParties.includes('On Behalf Consignee') ? 'tertiary' : ''"  @click="templateTag('On Behalf Consignee')">On Behalf Consignee</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Forwarder')" :color="templateCreate.allowedParties.includes('On Behalf Forwarder') ? 'tertiary' : ''"  @click="templateTag('On Behalf Forwarder')">On Behalf Forwarder</v-chip>
                                    <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Buyer')" :color="templateCreate.allowedParties.includes('On Behalf Buyer') ? 'tertiary' : ''"  @click="templateTag('On Behalf Buyer')">On Behalf Buyer</v-chip>
                                </div>
                                <v-subheader style="height: 30px;" class="px-0 mt-3" v-if="!hideCategoryTags">
                                    Tags
                                </v-subheader>
                                <v-divider v-if="!hideCategoryTags"></v-divider>
                                <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;" v-if="!hideCategoryTags">
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Logistical Docs')" :color="templateCreate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags')">Logistical Docs</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Finance')" :color="templateCreate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags')">Finance</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Bookings')" :color="templateCreate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags')">Bookings</v-chip>
                                    <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Client')" :color="templateCreate.tags.includes('Client') ? 'greyBase' : ''" small @click="templateTag('Client', 'tags')">Client</v-chip>
                                </div>
                                <div class="mt-3 d-flex justify-center align-center">
                                    <v-btn color="primary" :disabled="!templateCreate.title" @click="saveTemplate('DefaultTemplate')" :loading="loadingTemplateSave">Save</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="loadingTemplates">
                <v-col cols="12" sm="12" md="6" lg="6" v-for="(i) in 4" :key="i" class="px-1 py-1">
                    <v-card flat class="d-flex pa-1 align-center" style="border-radius: 8px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten2) !important;">
                        <div style="max-height: 67px; max-width: 67px; height: 67px; width: 67px; contain: content; border-radius: 8px; overflow: hidden;">
                            <v-skeleton-loader type="image"></v-skeleton-loader>
                        </div>
                        <v-skeleton-loader class="ml-2" style="width: 60%;" type="sentences"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>
export default {
    data: () => ({
        templateCreate: {
            title: null,
            description: null,
            allowedParties: ['Everyone'],
            tags: [],
            filters: [],
        },
        selectedTemplate: null,
        hideAvailableTags: false,
        hideCategoryTags: false,
        loadingTemplateSave: false,
        loadingTemplates: false,
        orgTemplates: [],
        userTemplates: [],
        defaultTemplates: [],
        gridApi: null,
        tableType: null
    }),
    beforeMount() {
        this.tableType = this.params.tableType
        this.getTemplates()
    },
    mounted() {
        this.gridApi = this.params.api
        this.hideAvailableTags = this.params.hideAvailableTags ?? false
        this.hideCategoryTags = this.params.hideCategoryTags ?? false
        console.log(`${this.tableType} Loaded`, this.gridApi)
        let selectedTemplate = JSON.parse(localStorage.getItem(`gridTemplate-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`))
        this.selectedTemplate = selectedTemplate ?? null
        let pivot = localStorage.getItem(`pivotMode-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)
        let filterModel = localStorage.getItem(`filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)
        if(localStorage.getItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)) {
            this.gridApi.applyColumnState({state: JSON.parse(localStorage.getItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)), applyOrder: true })
        }
        if(pivot) {
            pivot = JSON.parse(pivot)
            this.gridApi.setPivotMode(JSON.parse(pivot.pivotMode))
        } else if(this.selectedTemplate?.pivotMode) {
            this.gridApi.setPivotMode(this.selectedTemplate.pivotMode)
        }
        if(filterModel) {
            console.log(`${this.tableType} Filters`, JSON.parse(filterModel))
            this.gridApi.setFilterModel(JSON.parse(filterModel))
        }
    },
    watch: {
        selectedTemplate: {
            immediate: true,
            handler(val) {
                if(val && this.params.fSetTemplate) {
                    this.params.setTemplate(val)
                }
            }
        },

    },
    beforeDestroy() {
        console.log('unmounting')
        let columnState = this.gridApi.getColumnState()
        localStorage.setItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(columnState))
        localStorage.setItem(`gridTemplate-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.selectedTemplate))
    },
    methods: {
        async getTemplates(force) {
            if(force || this.defaultTemplates.length == 0) {
                this.loadingTemplates = true
                let result = await this.$API.getTradingTemplates({params: {tableType: this.tableType}});
                if(result) {
                    this.orgTemplates = result.organisationTemplates ?? []
                    this.userTemplates = result.userTemplates ?? []
                    this.defaultTemplates = result.defaultTemplates ?? []
                }
                this.loadingTemplates = false
            }
        },
        templateTag(tag, category, prop) {
            let ref = this.templateCreate
            if(prop && prop == 'selected') {
                ref = this.selectedTemplate
            }
            if(typeof ref.allowedParties == 'string') {
                ref.allowedParties = JSON.parse(ref.allowedParties)
            }
            if(typeof ref.tags == 'string') {
                ref.tags = JSON.parse(ref.tags)
            }
            if(!category || category != 'tags') {
                let find = ref.allowedParties.find(x => x == tag)
                if(find) {
                    ref.allowedParties = ref.allowedParties.filter(x => x != tag)
                } else {
                    if(tag == 'Everyone') {
                        ref.allowedParties = ['Everyone']
                    } else {
                        if(ref.allowedParties.find(x => x == 'Everyone')) {
                            ref.allowedParties = ref.allowedParties.filter(x => x != 'Everyone')
                        }
                        ref.allowedParties.push(tag)
                    }
                }
            } else {
                let find = ref.tags.find(x => x == tag)
                if(find) {
                    ref.tags = ref.tags.filter(x => x != tag)
                } else {
                    ref.tags.push(tag)
                }
            }
        },
        changeTemplate(template, type) {
            let templateType = type == 'UserTemplate' ? this.userTemplates : type == 'OrgTemplate' ? this.orgTemplates : this.defaultTemplates
            let find = templateType.find(x => x.id == template.id)
            if( find && find.columns) {
                this.gridApi.applyColumnState({state: JSON.parse(find.columns), applyOrder: true})
                if(find.pivotMode) {
                    this.gridApi.setPivotMode(find.pivotMode)
                } else {
                    this.gridApi.setPivotMode(false)
                }
                localStorage.setItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(JSON.parse(find.columns)))
                this.selectedTemplate = find
                localStorage.setItem(`gridTemplate-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(find))
                if(find.filters) {
                    this.gridApi.setFilterModel(JSON.parse(find.filters))
                }
            }
        },
        async saveTemplate(type) {
            this.loadingTemplateSave = true
            let columns = this.gridApi.getColumnState()
            let filters = this.gridApi.getFilterModel()
            let pivotMode = this.gridApi.isPivotMode()
            let payload = {
                title: this.templateCreate.title,
                description: this.templateCreate.description,
                allowedParties: typeof this.templateCreate.allowedParties == 'string' ? this.templateCreate.allowedParties : JSON.stringify(this.templateCreate.allowedParties),
                tags: typeof this.templateCreate.tags == 'string' ? this.templateCreate.tags : JSON.stringify(this.templateCreate.tags),
                columns: JSON.stringify(columns),
                filters: JSON.stringify(filters),
                type: type,
                pivotMode: pivotMode,
                tableType: this.tableType
            }
            let result = await this.$API.saveTraderTemplate(payload)
            if(result) {
                this.$message.success('Template Saved')
                this.templateCreate.title = ''
                this.templateCreate.description = ''
                this.templateCreate.allowedParties = ['Everyone']
                this.templateCreate.tags = []
                this.templateCreate.filters = []
                this.getTemplates(true)
            } else {
                this.$message.error('Error Saving Template')
            }
            this.loadingTemplateSave = false
        },
        async updateTemplate(type) {
            this.loadingTemplateSave = true
            let columns = this.gridApi.getColumnState()
            let filters = this.gridApi.getFilterModel()
            let pivotMode = this.gridApi.isPivotMode()
            let payload = {
                id: this.selectedTemplate.id,
                title: this.selectedTemplate.title,
                description: this.selectedTemplate.description,
                allowedParties: typeof this.selectedTemplate.allowedParties == 'string' ? this.selectedTemplate.allowedParties : JSON.stringify(this.selectedTemplate.allowedParties),
                tags: typeof this.selectedTemplate.tags == 'string' ? this.selectedTemplate.tags : JSON.stringify(this.selectedTemplate.tags),
                columns: JSON.stringify(columns),
                filters: JSON.stringify(filters),
                type: type,
                pivotMode: pivotMode,
                tableType: this.tableType,
                lastUpdatedBy: this.$store.state.user.id
            }
            let result = await this.$API.updateTraderTemplate(payload)
            if(result) {
                await this.getTemplates(true)
                let whichTemplates = type == 'UserTemplate' ? this.userTemplates : type == 'OrgTemplate' ? this.orgTemplates : this.defaultTemplates
                if(whichTemplates.length > 0) {
                    let find = whichTemplates.find(x => x.id == this.selectedTemplate.id)
                    this.selectedTemplate = find
                    localStorage.setItem(`gridTemplate-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.selectedTemplate))
                    this.$message.success('Template Updated')
                }
            } else {
                console.log('Error Updating Template')
            }
            this.loadingTemplateSave = false
        },
        async deletedSelectedTemplate() {
            this.loadingTemplateSave = true
            let payload = {
                isDeleted: true,
                isActive: false,
                id: this.selectedTemplate.id
            }
            let result = await this.$API.updateTraderTemplate(payload)
            if(result) {
                this.getTemplates(true)
            this.loadingTemplateSave = false
            } else {
                console.log('Error Deleting Template')
            }
        },
    }
}
</script>

<style>
.chipIcon {
    margin-right: 3px;
    font-size: 16px !important;
}
</style>